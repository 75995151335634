import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "../../components/Modal/Modal.css";
import { formatArrCP1 } from "../functions/CP_Zona_Status";
import axios from "axios";
import Cookies from "universal-cookie";
import { correos_copia } from "../correosCopia";

const AsignarCP = ({ data }) => {
  const cookies = new Cookies();
  const [reqstatus, setReqstatus] = useState(0);
  const [open, setOpen] = useState(false);
  const [tkn] = useState(cookies.get("tkn"));

  const OpenM = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (data) {
      if (data.records.length > 1) {
        async function callAsync() {
          var x = await formatArrCP1(data.records);
         

          x.forEach((element) => {
            // - Renombrar keys -
            const newArrayOfObj = element.clientesp.map(
              ({
                REGISTRADO: FchSolicitud,
                CI_RIF: Ced_Rif,
                UBICACION_CP: Ubicacion_Cliente,
                NAP: Nombre_Nap,
                ...rest
              }) => ({
                FchSolicitud,
                Ced_Rif,
                Ubicacion_Cliente,
                Nombre_Nap,
                ...rest,
              })
            );

            var mail_body = {
              Destinatario: element.descripcion,
              Email_Destinatario: "pruebas.innovacion@airtek.com.ve",
              Asunto: "Asignación de Clientes Potenciales🚀",
              CopiaOculta: correos_copia,
              ClientePotencial: newArrayOfObj,
            };

            var count = 0;

            // funcion enviar correo
            async function sendMail() {
              axios
                .post(
                  `${process.env.REACT_APP_API}${process.env.REACT_APP_ASIGNAR_CP_EMAIL}`,
                  mail_body,
                  {
                    headers: {
                      Authorization: `Bearer ${tkn}`,
                    },
                  }
                )
                .then(function (response) {
                  if (response.status === 204) {
                    count = count + 1;

                    // enviar respuesta al final del loop
                  }

                  if (count === x.length) {
                    setOpen(true);
                    setReqstatus({
                      status: 204,
                      info: x,
                    });
                  }
                })
                .catch(function (error) {
                  console.error("Error:", error);
                });
            }

            // asignar clientes potenciales al aliado
            (async function () {
              Promise.all(
                element.clientesp.map((id) =>
                  fetch(
                    `${process.env.REACT_APP_API}${process.env.REACT_APP_ASIGNAR_CP}`,
                    {
                      method: "PATCH",
                      body: JSON.stringify({
                        id: `${id.ID}`,
                        cod_ac: `${element.cod_ac}`,
                        nom_ac: `${element.descripcion}`,
                        usuario: `sistemas@airtek.com.ve`,
                      }),
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${tkn}`,
                      },
                    }
                  )
                )
              )

                .then(async (responses) => {
                  // status de cada request
                  var resp = [];
                  responses.forEach((result) => {
                    resp.push(result.status);
                  });

                  // si todas son success, enviar email
                  if (resp.every((e) => e === 204) === true) {
                   await sendMail();
                  } else {
                    throw new Error("Email");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            })();
          });
        }
        callAsync();
      }
    }
  }, [data, tkn]);

  return (
    <>
      <ReactModal
        appElement={document.querySelector(".App")}
        isOpen={open}
        className="modal modal-dialog-centered w-100"
        tabIndex="-1"
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setOpen(!open)}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, .5)",
          },
        }}
      >
        <div className="modal-dialog">
          <div className="modal-content modal1">
            <div className="modal-header mhdr">
              <button
                type="button"
                className="btn-close close-modal mt-1 me-1"
                onClick={() => OpenM()}
              ></button>
            </div>

            <div className="modal-body mbdy">
              <div className="text-center">
                <i className="fa-solid fa-circle-check modal-icon-success mb-2 fa-2xl"></i>
                <h4 className="modal-success mt-3 fw-bold">¡Muy Bien!</h4>

                <hr className="separator mb-3 mt-3" />
                <p>Aliados y cantidad de Clientes Potenciales asignados: </p>
                <ul
                  className="list-group mx-auto mt-4 mb-4"
                  style={{
                    height: "250px",
                    width: "100%",
                    overflowY: "scroll",
                  }}
                >
                  {reqstatus &&
                    reqstatus.info.map((sucursal) => (
                      <li
                        className="list-group-item d-flex justify-content-between align-items-start"
                        key={sucursal.descripcion}
                      >
                        <div className="ms-2 me-auto">
                          <div className="fw-bold"> {sucursal.descripcion}</div>
                        </div>
                        <span className="badge bg-primary rounded-pill">
                          {sucursal.clientesp.length}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default AsignarCP;
