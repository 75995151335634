import React, { useState, useEffect } from "react";
import Sidebar from "../../components/shared/Sidebar/Sidebar";
import Navbar from "../../components/shared/Navbar/Navbar";
import Error403 from "../Error403/Error403";
import Button from "../../components/shared/Button/Button";
import Cookies from "universal-cookie";
import Select1 from "../../components/shared/Select/Select";
import axios from "axios";
import "./OrdersxTime.css";
import Modal1 from "../../components/Modal/Modal";

const OrdersxTime = () => {
  const cookies = new Cookies();
  const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("isLogedIn")));
  const [search, setSearch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentVal, setcurrentVal] = useState(null);
  const [tkn] = useState(cookies.get("tkn"));
  const [formInfo, setformInfo] = useState({
    valor: "",
    comentario: "",
  });
  const [modalInfo, setModalInfo] = useState({
    open: false,
    modalType: 3,
    icon: "",
    title_class: "",
    modalTitle: "",
  });
  const [response, setresponse] = useState(null);

  var options = [
    {
      value: "1",
      label: "Máximo permitido de registros con igual número de documento",
    },
    {
      value: "2",
      label: "Tiempo máximo de asignación de un prospecto a un AC",
    },
    {
      value: "3",
      label:
        "Tiempo máximo de asignación de un prospecto a un AC cuando el MDT es VGT",
    },
    {
      value: "4",
      label:
        "Tiempo máximo de reajuste para las solicitudes de prospectos (Instalación física: “Lista”)",
    },
    {
      value: "5",
      label: "Máximo permitido de registros con número de teléfono igual.",
    },
  ];

  useEffect(() => {
    const getData = () => {
      if (search) {
        axios
          .get(
            `${process.env.REACT_APP_API}${process.env.REACT_APP_SETTINGS}v1=${process.env.REACT_APP_SETTINGS_ID}&v2=${search}`,
            {
              headers: {
                Authorization: `Bearer ${tkn}`,
              },
            }
          )
          .then(async (res) => {
            var info = JSON.parse(res.data);
            setcurrentVal(info.response);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    getData();
  }, [search, tkn]);

  // modal
  const Open = () => {
    setModalInfo({
      ...modalInfo,
      open: !modalInfo.open,
    });
  };

  const onSubmit = () => {
    setIsLoading(true);

    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");

    formInfo["modifiedby"] = base64ToStringNew;
    formInfo["accion"] = search;
    formInfo["id"] = `${process.env.REACT_APP_SETTINGS_ID}`;

    fetch(`${process.env.REACT_APP_API}${process.env.REACT_APP_SETTINGS}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tkn}`,
      },
      body: JSON.stringify(formInfo),
    })
      .then((response) => response.json())
      .then((json) => {
        setIsLoading(false);
        var resp = JSON.parse(json);
        setresponse(resp);

        Open();

        if (resp.status) {
          setcurrentVal(null);

          axios
            .get(
              `${process.env.REACT_APP_API}${process.env.REACT_APP_SETTINGS}v1=${process.env.REACT_APP_SETTINGS_ID}&v2=${search}`,
              {
                headers: {
                  Authorization: `Bearer ${tkn}`,
                },
              }
            )
            .then(async (res) => {
              var info = JSON.parse(res.data);
              setcurrentVal(info.response);
              setformInfo({
                valor: "",
                comentario: "",
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setresponse(null);
        console.error("Error:", error);
      });
  };

  return (
    <>
      {isLoggedIn === true ? (
        <div className="wrapper w-100 d-flex align-items-stretch">
          <Sidebar />
          <div id="content" className="main-cnt">
            <div className="container-fluid main-ct w-100 ">
              <Navbar />
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="p-4">
                    <p className="asignacion-title text-center">
                      Configuraciones establecidas - Dashboard Comercial
                    </p>
                  </div>

                  <div className="d-flex w-100">
                    <div className="card p-4 m-auto gap-4 card-config">
                      <div className="d-flex flex-column justify-content-center w-100">
                        <label className="label-form">Configuración </label>
                        <Select1
                          data={options}
                          onSearch={(value) => {
                            setSearch(value);
                            if (value !== search) {
                              setcurrentVal(null);
                            }
                          }}
                          placeholder={"Seleccionar valor..."}
                        />
                      </div>

                      {currentVal && (
                        <>
                          <div className="d-flex w-100 justify-content-center align-items-center">
                            <label className="label-form">
                              Cantidad Actual:{" "}
                            </label>
                            <p className="ms-1 fw-bold mb-0">
                              {currentVal.valor}
                              {(search !== "1" && search !== "5") && (
                                <span className="ms-1">h</span>
                              )}
                            </p>
                          </div>

                          <div className="d-flex flex-column justify-content-center w-100">
                            <label className="label-form">
                              Actualizar cantidad
                            </label>
                            <input
                              type="number"
                              min={1}
                              inputMode="numeric"
                              className="form-control-ac border-end rounded-pill border-secondary"
                              onChange={(e) => {
                                var valueDate = e.target.value.trim();

                                if (valueDate) {
                                  setformInfo((prevState) => ({
                                    ...prevState,
                                    valor: valueDate,
                                  }));
                                }
                              }}
                            />
                          </div>

                          <div className="d-flex flex-column justify-content-center w-100">
                            <label className="label-form">Comentario </label>
                            <textarea
                              className="form-control-ac border-secondary border-end"
                              style={{
                                resize: "none",
                              }}
                              onChange={(e) => {
                                var valueDate = e.target.value.trim();

                                setformInfo((prevState) => ({
                                  ...prevState,
                                  comentario: valueDate,
                                }));
                              }}
                            ></textarea>
                          </div>

                          <div
                            style={{
                              pointerEvents: !Object.values(formInfo).every(
                                (field) => !!field
                              )
                                ? "none"
                                : "auto",
                              opacity: !Object.values(formInfo).every(
                                (field) => !!field
                              )
                                ? "0.2"
                                : "1",
                            }}
                          >
                            <Button
                              text={"Guardar"}
                              loading={isLoading}
                              myClass={"btn btn-save pe-4 ps-4 w-100"}
                              funct={onSubmit}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Error403 />
      )}

      <Modal1
        open={modalInfo.open}
        modalType={modalInfo.modalType}
        close={() => Open()}
        icon={
          response && !response.status
            ? "fa-solid fa-circle-xmark mb-2 fa-2xl modal-icon-error"
            : "fa-solid fa-circle-check modal-icon-success mb-2 fa-2xl"
        }
        title_class={"mt-3 modal-error-txt"}
        modalTitle={response && response.message}
      />
    </>
  );
};

export default OrdersxTime;
