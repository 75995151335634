import React from "react";
import "./Timeline.css";
import { trackingColors } from "../../views/Tracking/trackingColors";
const defaultColor = "#000";
const defaultBackgroundColor = "#fff";

const TimelineItem = ({ step, fecha, usuario }) => {
  var stepName = step.split("-")[0].trim();
  var color = trackingColors[stepName]?.solid || defaultColor;
  var backgroundColor =

    trackingColors[stepName]?.light || defaultBackgroundColor;
  var icon = trackingColors[stepName]?.icon;

  return (
    <tr>
      <td>
        <div
          className="tracking-icon-wrapper"
          style={{
            backgroundColor: backgroundColor,
            color: color,
          }}
        >
          {icon && <img src={icon} className="tracking-icon" alt="Step Icon" />}
        </div>
      </td>
      <td>
        <div
          className="step-status-btn"
          style={{
            backgroundColor: backgroundColor,
            color: color,
          }}
        >
          {step.toUpperCase()}
        </div>
      </td>
      <td className="timeline-time">{fecha.replace("T", " ")}</td>
      <td className="timeline-time">
        <div style={{ width: "140px", wordWrap: "break-word" }}>
          {usuario}
        </div>
      </td>

    </tr>





  );
};

const HorizontalTimeline = ({ data }) => {
  return (
    <div
      style={{
        maxHeight: "300px",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <table className="tracking-modal-table">
        <thead className="thead-tracking">
          <tr>
            <td></td>
            <td>Estatus</td>
            <td>Fecha</td>
            <td>Usuario</td>
          </tr>
        </thead>


        <tbody className="tbody-tracking">
          {data.tracking.map((element, i) => {
            return (
              <TimelineItem key={i} step={element.step} fecha={element.fecha} usuario={element.usuario} />
            );
          })}
        </tbody>

      </table>
    </div>
  );
};

export default HorizontalTimeline;
