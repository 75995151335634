import React, { useState, useEffect } from "react";
import "./../Modal.css";
import ReactModal from "react-modal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "./ModalSlider.css";

const ModalSlider = ({ open, close, uInfo }) => {
  const [openM, setOpen] = useState();
  const [info, setinfo] = useState([]);

  SwiperCore.use([Navigation]);

  useEffect(() => {
    setOpen(open);

    Object.keys(uInfo).forEach((key) => {
      if (uInfo[key] === " ") {
        delete uInfo[key];
      }
    });

    var values = Object.values(uInfo);
    var final = [];
    var counter = 0;
    var portion = {};

    for (var key in uInfo) {
      if (counter !== 0 && counter % 5 === 0) {
        final.push(portion);
        portion = {};
      }
      portion[key] = values[counter];
      counter++;
    }
    final.push(portion);

    setinfo(final);
  }, [open, uInfo]);

  return (
    <ReactModal
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered w-100"
      tabIndex="-1"
      shouldCloseOnOverlayClick={true}
      onRequestClose={close}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content modal1">
          <div className="modal-header mhdr">
            <button
              type="button"
              className="btn-close close-modal mt-1 me-1"
              onClick={close}
            ></button>
          </div>

          <div className="modal-body mbdy h-100">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={30}
              centeredSlides={true}
              loop={true}
              autoHeight={true}
              navigation={true}
            >
              {info &&
                info.map((d, index) => (
                  <SwiperSlide key={index}>
                    <div className="d-flex">
                      <div className="card-ms mx-auto">
                        {Object.entries(d).map(([key, val], i) => (
                          <div
                            className="d-flex flex-column me-5 text-start w-100"
                            key={i}
                          >
                            {key === "Estatus_Senal" ? (
                              <label className="text-start text-label">
                                Estatus Señal
                              </label>
                            ) : (
                              <label className="text-start text-label">
                                {(
                                  key.charAt(0).toUpperCase() + key.slice(1)
                                ).replace(/_/g, " ")}
                              </label>
                            )}
                            <p
                              className="modal-text-1"
                              style={{
                                flex: 1,
                                wordWrap: "break-word",
                              }}
                            >
                              {val}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ModalSlider;
