import React, { useState, useEffect, useMemo } from "react";
import "../Modal.css";
import "./ModalStep.css";
import ReactModal from "react-modal";
import Select1 from "../../shared/Select/Select";
import Cookies from "universal-cookie";
import axios from "axios";
import Button from "../../shared/Button/Button";

const ModalStep = ({ open, close, cod, id }) => {
  const [openM, setOpen] = useState();
  const cookies = new Cookies();
  const [motivos, setmotivos] = useState([]);
  const [sent, setSent] = useState({
    status: false,
    response: "",
    error: false,
  });
  const [tkn] = useState(cookies.get("tkn"));
  const [data, setdata] = useState({
    id: 0,
    codigo_motivo: 0,
    comentario: "",
    usuario: "",
  });

  useEffect(() => {
    if (cod && cod.length >= 1) {
      setdata((prevData) => ({
        ...prevData,
        id: cod[0].ID,
      }));
    }
  }, [cod]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");
    setdata((prevData) => ({
      ...prevData,
      usuario: base64ToStringNew,
    }));
  }, []);

  useEffect(() => {
    if (id !== 0) {
      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_TRACKING_DESC}${id}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then(async (res) => {
          var resp = await JSON.parse(res.data);
          setmotivos(resp.response);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id, tkn]);

  const listaMotivos = useMemo(
    () => [
      ...new Set(
        motivos.map((item) => {
          return { label: item.motivo, value: item.codmotivo };
        })
      ),
    ],

    [motivos]
  );

  const send = () => {
    var url =
      id === 8
        ? `${process.env.REACT_APP_TRACKING_CANCELAR}`
        : `${process.env.REACT_APP_TRACKING_PAUSAR}`;

    axios
      .patch(`${process.env.REACT_APP_API}${url}`, data, {
        headers: {
          Authorization: `Bearer ${tkn}`,
        },
      })
      .then(function (response) {
        setSent((prevData) => ({
          ...prevData,
          status: response.status === 200 ? true : false,
          response: JSON.parse(response.data),
          error: response.status === 200 ? false : true,
        }));
      })
      .catch(function (error) {
        console.log(error);
        setSent({
          error: true,
        });
      });
  };

  return (
    <ReactModal
      onRequestClose={close}
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered"
      tabIndex="-1"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content modal1">
          <div className="modal-header mhdr">
            <button
              type="button"
              className="btn-close close-modal"
              onClick={() => {
                close();
                if (sent && sent.status) {
                  setTimeout(() => {
                    window.location.reload();
                  }, 800);
                }
              }}
            ></button>
          </div>

          <div className="modal-body mbdy">
            <div>
              {sent && !sent.status && !sent.error ? (
                <div className="formStep">
                  <div>
                    <label className="fw-bold">
                      {id === 8 ? "Cancelar" : "Pausar"} orden
                    </label>
                    <div className="d-flex align-items-center">
                      <div className="circle-order rounded-circle"></div>
                      <p className="mb-0 ms-2 tracking-id">
                        #{data ? data.id : 0}
                      </p>
                    </div>
                  </div>

                  <div className="d-grid gap-4 pt-3">
                    <div className="d-flex flex-column gap-2">
                      <label>Motivo</label>
                      <Select1
                        onSearch={(value) => {
                          setdata((prevData) => ({
                            ...prevData,
                            codigo_motivo: value,
                          }));
                        }}
                        data={listaMotivos}
                        placeholder={"Seleccione"}
                        custom={true}
                      />
                    </div>

                    <div className="d-flex flex-column gap-2">
                      <label>Comentario</label>
                      <textarea
                        className="txtarea-step"
                        style={{
                          resize: "none",
                        }}
                        onChange={(e) => {
                          setdata((prevData) => ({
                            ...prevData,
                            comentario: e.target.value.trim(),
                          }));
                        }}
                      ></textarea>
                    </div>

                    <div
                      style={{
                        pointerEvents: !Object.values(data).every(
                          (field) => !!field
                        )
                          ? "none"
                          : "auto",
                        opacity: !Object.values(data).every((field) => !!field)
                          ? "0.2"
                          : "1",
                      }}
                    >
                      <Button
                        text={"Guardar"}
                        leftIcon={"fa-solid fa-arrow-right"}
                        myClass={`btn btn-primary rounded-pill ps-4 pe-4`}
                        funct={send}
                        loadingMsg={"Guardando"}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {sent && sent.error ? (
                    <div className="text-center">
                      <i className="fa-solid fa-circle-xmark mb-4 fa-2xl modal-icon-error"></i>
                      <p className="modal-error-txt">
                        Se produjo un error. Vuelve a intentarlo más tarde.
                      </p>
                    </div>
                  ) : (
                    <div className="text-center">
                      <i className="fa-solid fa-circle-check modal-icon-success mb-4 fa-2xl"></i>
                      <p className="modal-error-txt">{sent.response.message}</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ModalStep;
