import { React, useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Autocomplete,
} from "@react-google-maps/api";
import marker from "../../../assets/svg/ubicacion-usuario.svg";
import enconstruccion from "../../../assets/svg/enconstruccion.svg";
import sinestatus from "../../../assets/svg/sinestatus.svg";
import disenocerrado from "../../../assets/svg/disenocerrado.svg";
import exploracioncomercial from "../../../assets/svg/exploracioncomercial.svg";
import enoperacion from "../../../assets/svg/enoperacion.svg";
import endiseno from "../../../assets/svg/endiseno.svg";
import plan50 from "../../../assets/svg/plan50.svg";
import plan600 from "../../../assets/svg/plan600.svg";
import "./Map.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Status from "../../Status/Status";
import { validCoords } from "../../../utils/Regex/Regex";
import axios from "axios";
import Cookies from "universal-cookie";

const Map = ({ data, mdt, coordsUser }) => {
  const cookies = new Cookies();
  const [coords, setCords] = useState({ lat: "", lng: "" });
  const [libraries] = useState(["places"]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [autocomplete_v, setAutocomplete_v] = useState("");
  const [coordsErr, setCoordsErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableLocation, settableLocation] = useState(false);
  const [tkn] = useState(cookies.get("tkn"));
  // Map styles

  const containerStyle = {
    height: "400px",
    borderRadius: "12px",
  };

  // save autocomplete selected option

  function onLoad(autocomplete) {
    setAutocomplete_v(autocomplete);
  }

  // get place id based on selected option

  function onPlaceChanged() {
    setIsLoading(true);

    if (autocomplete_v !== null && coordsErr === false) {
      // fetch(
      //   `${process.env.REACT_APP_API}${process.env.PLACE_DETAILS}id=${autocomplete_v.getPlace().place_id}&tk=${process.env.REACT_APP_PLACE_TKN}`
      // )
      //   .then((response) => response.json())
      //   .then((location) => {
      //     setIsLoading(false);
      //     setCords(location); // get coords from that specific place
      //   });

      axios
        .get(
          `${process.env.REACT_APP_API}${
            process.env.REACT_APP_PLACE_DETAILS
          }id=${autocomplete_v.getPlace().place_id}&tk=${
            process.env.REACT_APP_PLACE_TKN
          }`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then((res) => {
          var infoCoords = JSON.parse(res.data);
          setCords(infoCoords.result.geometry.location);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    } else {
      console.log("Autocomplete has not loaded yet.");
    }
  }

  // marker that was clicked

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        alert("La geolocalización no es compatible con este navegador.");
      }
    };

    // get user location

    function showPosition(position) {
      setCords({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    }

    if (
      Object.values(coordsUser).every((x) => x === null || x === "") === false
    ) {
      settableLocation(true);
    }

    getLocation();
  }, [coordsUser]);

  // center of the map **

  const defaultcenter = {
    lat: 10.6752146,
    lng: -71.6106945,
  };

  function handleInput(event) {
    validate(event.target.value);
  }

  // send input value to validate

  const validate = (value) => {
    if (value !== "") {
      if (validCoords.test(value.replace(/\s/g, ""))) {
        setCoordsErr(true);
        setCords({
          lat: parseFloat(value.split(",")[0]),
          lng: parseFloat(value.split(",")[1]),
        });
      } else {
        setCoordsErr(false);
      }
    }
  };

  return (
    <>
      <LoadScript
        language={"es"}
        region={"ve"}
        googleMapsApiKey={process.env.REACT_APP_API_GOOGLE_MAPS}
        libraries={libraries}
        loadingElement={<Skeleton height={400} />}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          clickableIcons={true}
          mapTypeId={"terrain"}
          mapContainerClassName={"mapContainer"}
          zoom={
            Object.values(coords).every((x) => x === null || x === "") ===
              false && tableLocation === false
              ? 20
              : Object.values(coordsUser).every(
                  (x) => x === null || x === ""
                ) === false && tableLocation === true
              ? 30
              : 20
          }
          options={{
            streetViewControl: false,
          }}
          center={
            Object.values(coords).every((x) => x === null || x === "") ===
              false && tableLocation === false
              ? coords
              : Object.values(coordsUser).every(
                  (x) => x === null || x === ""
                ) === false && tableLocation === true
              ? {
                  lat: parseFloat(coordsUser.lat),
                  lng: parseFloat(coordsUser.lng),
                }
              : defaultcenter
          }
        >
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <div className="input-group searchbar-map">
              <input
                type="text"
                placeholder="Buscar"
                onChange={(e) => {
                  handleInput(e);
                }}
                className="form-control input-map"
              />
              {isLoading === true ? (
                <span
                  className="input-group-text sb-icon-wrp"
                  id="basic-addon2"
                >
                  <div
                    className="spinner-border spinner-border-sm ms-3"
                    role="status"
                  >
                    <span className="sr-only">Cargando...</span>
                  </div>
                </span>
              ) : null}
            </div>
          </Autocomplete>

          <>
            {Object.values(coords).every((x) => x === null || x === "") ===
              false && (
              <Marker
                draggable={false}
                clickable={false}
                animation={1}
                position={coords}
                icon={{
                  url: marker,
                  scaledSize: { width: 80, height: 80 },
                }}
              />
            )}

            {data.map((d, index) => (
              <div key={index}>
                <Marker
                  clickable={true}
                  position={{
                    lat: parseFloat(
                      `${d.UBICACION_CP.split(",")[0].slice(0, -1)}`
                    ),
                    lng: parseFloat(`${d.UBICACION_CP.split(",")[1]}`),
                  }}
                  icon={{
                    url:
                      mdt === true
                        ? d.ESTATUS_MDT === "EN OPERACION"
                          ? enoperacion
                          : d.ESTATUS_MDT === "EXPLORACION COMERCIAL"
                          ? exploracioncomercial
                          : d.ESTATUS_MDT === "EN CONSTRUCCION"
                          ? enconstruccion
                          : d.ESTATUS_MDT === "DISEÑO CERRADO"
                          ? disenocerrado
                          : d.ESTATUS_MDT === "EN DISEÑO"
                          ? endiseno
                          : sinestatus
                        : d.PLAN_SOLICITADO === "600 MEGAS"
                        ? plan600
                        : plan50,
                    scaledSize: { width: 50, height: 50 },
                  }}
                  options={{
                    optimized: false,
                  }}
                  onClick={() => handleActiveMarker(index)}
                >
                  {activeMarker === index ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                      <div className="text-center p-1 d-flex flex-column">
                        <div className="mb-2">
                          <span className="infow-title">Aliado Comercial:</span>
                          <p className="infow-text">{d.MDT_AC}</p>
                        </div>

                        <div className="mb-2 d-flex align-items-center mx-auto">
                          <span className="infow-title me-2">MDT:</span>
                          <p className="infow-text mb-0">{d.MDT}</p>
                        </div>

                        <div>
                          <Status title={d.ESTATUS_MDT} />
                        </div>
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              </div>
            ))}
          </>
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default Map;
