import React, { useEffect, useState } from "react";
import TableHeader from "../../components/shared/TableHeader/TableHeader";
import Pagination from "react-js-pagination";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Tracking.css";
import Select1 from "../../components/shared/Select/Select";
import Modal1 from "../../components/Modal/Modal";
import searchIcon from "../../assets/img/search.png";
import { trackingColors } from "./trackingColors";
import emptyData from "../../assets/img/empty.png";

const TrackingList = () => {
  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 7;
  const cookies = new Cookies();
  const [modalType, setmodalType] = useState(null);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [value, onChange] = useState(null);
  const defaultColor = "#000";
  const defaultBackgroundColor = "#fff";
  const [total_Records, settotal_Records] = useState(0);

  const [tkn] = useState(cookies.get("tkn"));

  const [searchQuery, setsearchQuery] = useState({
    searchType: "0",
    search: "",
  });

  // modal
  const Open = () => {
    setModal(!modal);
  };

  useEffect(() => {
    async function getData() {
      var base_url;

      if (modalType) {
        base_url = `${process.env.REACT_APP_API}${process.env.REACT_APP_TRACKING_DETALLE}v=${modalType.searchType}&x=${modalType.search}&p=${currentPage}&r=${ITEMS_PER_PAGE}`;
      } else {
        base_url = `${process.env.REACT_APP_API}${process.env.REACT_APP_TRACKING_DETALLE}p=${currentPage}&r=${ITEMS_PER_PAGE}`;
      }

      axios
        .get(base_url, {
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        })
        .then(async (res) => {
          var instalaciones = JSON.parse(res.data);

          setError(false);
          settotal_Records(instalaciones.response.total_records);
          // setCurrentPage(1);
          // setIsLoading(false);
          setComments(instalaciones.response.records);
        })
        .catch((error) => {
          setError(true);
          // setIsLoading(false)

          console.log(error);
        });
    }

    getData();
  }, [currentPage, tkn, modalType]);

  const headers = [
    { name: "ID Tracking", field: "idtracking", sortable: false },
    { name: "C.I", field: "cedula", sortable: false },
    { name: "Nombre", field: "nombrecompleto", sortable: false },
    { name: "Teléfono", field: "telefono", sortable: false },
    { name: "Correo ", field: "email", sortable: false },
    { name: "Duplicado", field: "duplicado", sortable: false },
    { name: "Instalado", field: "instalado", sortable: false },
    { name: "Cancelado", field: "cancelado", sortable: false },
    { name: "Pausada", field: "pausada", sortable: false },
    { name: "Estatus", field: "step", sortable: false },
  ];

  const TableInformation = ({ info }) => {
    return (
      <>
        {info.map((comment, index) => {
          if (comment.tracking && comment.tracking.length !== 0) {
            var lastStep =
              comment.tracking[comment.tracking.length - 1].step || "";
            var stepName = lastStep.split("-")[0].trim();
            var color = trackingColors[stepName]?.solid || defaultColor;
            var backgroundColor =
              trackingColors[stepName]?.light || defaultBackgroundColor;
          }

          return (
            <tr key={index}>
              <td>{comment.idtracking}</td>
              <td>{comment.cedula}</td>
              <td>{comment.nombrecompleto}</td>
              <td>{comment.telefono}</td>
              <td>{comment.email}</td>
              <td>
                {comment.duplicado && <i className="fa-solid fa-check"></i>}
              </td>
              <td>
                {comment.instalado && <i className="fa-solid fa-check"></i>}
              </td>
              <td>
                {comment.cancelado && <i className="fa-solid fa-check"></i>}
              </td>
              <td>
                {comment.pausada && <i className="fa-solid fa-check"></i>}
              </td>

              <td>
                {comment.tracking && comment.tracking.length !== 0 && (
                  <button
                    className="step-status-btn"
                    onClick={() => {
                      onChange(comment);
                      Open();
                    }}
                    style={{
                      color: color ? color : defaultColor,
                      backgroundColor: backgroundColor
                        ? backgroundColor
                        : defaultBackgroundColor,
                    }}
                  >
                    {stepName ? stepName.toUpperCase() : ""}
                  </button>
                )}
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  const SearchInfo = () => {
    setCurrentPage(1);
    setmodalType(searchQuery);
  };

  return (
    <>
      <div className="mb-4">
        <div className="d-flex w-100 mb-4">
          <p className="mx-auto asignacion-title mb-0" style={{
            fontSize: 18
          }}>Tracking</p>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex w-100">
            <div className="d-flex align-items-center ms-auto">
              <div className="type-wrapper">
                <Select1
                  onSearch={(value) => {
                    setsearchQuery((prevData) => ({
                      ...prevData,
                      searchType: value,
                    }));
                  }}
                  data={[
                    { label: "Número de tracking", value: "1" },
                    { label: "Número de documento", value: "2" },
                  ]}
                  placeholder={"Tipo de búsqueda"}
                  zeroValue={true}
                />
              </div>
              <div className="input-group h-100">
                <input
                  type="text"
                  className="form-search-tracking"
                  placeholder={"Valor a buscar"}
                  value={searchQuery.search}
                  onChange={(e) =>
                    setsearchQuery((prevData) => ({
                      ...prevData,
                      search: e.target.value.trim(),
                    }))
                  }
                />

                <span
                  className="input-group-text p-0"
                  id="basic-addon2"
                  style={{
                    border: modalType
                      ? "1px solid hsl(0, 0%, 80%)"
                      : "transparent",
                    borderTopRightRadius: modalType ? "4px" : "12px",
                    borderBottomRightRadius: modalType ? "4px" : "12px",
                  }}
                >
                  <button
                    onClick={() => {
                      if (modalType) {
                        setmodalType(null);
                        setsearchQuery((prevData) => ({
                          ...prevData,
                          search: "",
                        }));
                      } else {
                        if (
                          searchQuery &&
                          searchQuery.search !== "" &&
                          searchQuery.searchType !== "0"
                        ) {
                          SearchInfo();
                        }
                      }
                    }}
                    className="btn-t-search"
                    style={{
                      backgroundColor: modalType ? "transparent" : "#0066ff",
                    }}
                  >
                    {modalType ? (
                      <i
                        className="fa-solid fa-xmark"
                        style={{
                          color: "hsl(0, 0%, 60%)",
                          paddingRight: "10px",
                        }}
                      ></i>
                    ) : (
                      <img src={searchIcon} alt="search icon" />
                    )}
                  </button>
                </span>
              </div>
            </div>
          </div>

          {!error ? (
            <>
              <div className="table-responsive mt-3">
                <table className="table table-b table-tracking table-bordered">
                  <TableHeader headers={headers} id={"selectAll"} />

                  <tbody>
                    <TableInformation info={comments} />
                  </tbody>
                </table>
              </div>

              <div className="row">
                <p className="mb-2 total-reg total-reg-ac">
                  Total Registros: <span>{total_Records}</span>
                </p>
              </div>

              <div className="d-flex w-100">
                <div className="pag-reportes">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    totalItemsCount={total_Records}
                    disabledClass={"disabled-page"}
                    pageRangeDisplayed={5}
                    onChange={(page) => setCurrentPage(page)}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-100 d-flex h-100">
                <div className="mx-auto my-auto mt-5 text-center">
                  <img
                    src={emptyData}
                    alt="Data no disponible"
                    className="m-auto"
                  />

                  <p
                    className="text-center"
                    style={{
                      color: "#AEB8C2",
                      fontWeight: "bold",
                    }}
                  >
                    No se han encontrado registros.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal1 open={modal} modalType={6} close={() => Open()} cod={value} />
    </>
  );
};

export default TrackingList;
