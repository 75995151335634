import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import "./ActivarTlf.css";
import Modal1 from "../../components/Modal/Modal";

const ActivarTlf = () => {
  const cookies = new Cookies();
  const [id, setId] = useState("");
  const [tkn] = useState(cookies.get("tkn"));
  const [data, setData] = useState(null);
  const [allMsjInfo, setAllMsjInfo] = useState(null);
  const [msj, setMsj] = useState(null);
  const [links, setLinks] = useState(null);
  const [loadingLink, setLoadingLink] = useState(false);
  const [responseLink, setResponseLink] = useState(null);
  const [modal, setModal] = useState(false);

  const Open = () => {
    setModal(!modal);
  };

  const handleLinkClick = () => {
    var smsId = allMsjInfo.id.toString();

    if (allMsjInfo && allMsjInfo.id) {
      setLoadingLink(true);
      let data = JSON.stringify({
        smsId: smsId,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_NOTIFICATION_MS}saveResponsible`,
        headers: {
          Authorization: `Bearer ${tkn}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setResponseLink(response);
          setLoadingLink(false);
          Open();
        })

        .catch((error) => {
          console.log(error);
          setLoadingLink(false);
        });
    }
  };

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API}clientepotencial?ced=${id}`, {
        headers: {
          Authorization: `Bearer ${tkn}`,
        },
      })
      .then(async (res) => {
        var info = res.data;
        if (info.length > 0) {
          setData(info[0]);
        } else {
          setData(0);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSearch = () => {
    if (id && id.length >= 7) {
      getData();
    }
  };

  useEffect(() => {
    if (data && data?.TELEFONO) {
      const tlf = data.TELEFONO.padStart(data.TELEFONO.length + 1, "0");
      axios
        .get(
          `${process.env.REACT_APP_NOTIFICATION_MS}getLastMessageByPhoneNumber?phoneNumber=${tlf}`
        )
        .then((res) => {
          var msj = res.data;
          setAllMsjInfo(msj.smsData);

          if (msj.smsData) {
            var text = msj.smsData.message.text;

            const httpsLinkRegex = /https:\/\/[^\s]+/g;
            const linkMatch = text.match(httpsLinkRegex);

            let link = null;
            let messageText = text;

            if (linkMatch) {
              link = linkMatch[0];
              messageText = text.replace(link, "").trim();
            } else {
              link = null;
            }

            if (messageText) {
              setMsj(messageText);
            } else {
              setMsj(null);
            }

            if (link) {
              setLinks(link);
            } else {
              setLinks(null);
            }
          }
        })
        .catch((error) => {
          setLinks(null);
          setAllMsjInfo(null);
          setMsj(null);
          console.error(error);
        });
    }
  }, [data]);

  return (
    <div className="container-fluid d-flex flex-column justify-content-center align-items-center pt-5">
      <div className="h-100 d-flex flex-column justify-content-center align-items-center gap-3">
        <div style={{ gap: "6px" }}>
          <p
            style={{ fontSize: "20px", fontFamily: "HeadingProWide" }}
            className="text-center mb-0"
          >
            ¡Activa el teléfono!
          </p>
          <p
            style={{ fontSize: "15px", fontWeight: "500", width: "70%" }}
            className="text-center m-auto"
          >
            Ingresa el documento de identidad para la actualización de datos
          </p>
        </div>

        <div
          className="form-group m-auto w-100 d-flex justify-content-center align-items-center"
          style={{ height: "50px" }}
        >
          <input
            type="text"
            placeholder="Documento de identidad"
            value={id}
            className="h-100 ps-3 search-input-id"
            style={{
              borderRadius: "10px",
              fontSize: "14px",
              border: "1px solid #11111199",
              width: "100%",
              maxWidth: "250px",
              borderRight: "none",
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
            }}
            onChange={(e) => {
              const value = e.target.value;

              if (/^\d{0,10}$/.test(value)) {
                setId(value);
              }
            }}
          />
          {id !== "" && (
            <button
              className="btn btn-search-id-clear btn btn-light h-100"
              style={{
                borderTop: "1px solid #11111199",
                borderBottom: "1px solid #11111199",
                borderRight: "0",
                borderLeft: "0",
                borderRadius: "0",
                fontSize: "14px",
                boxShadow: "none",
              }}
              onClick={() => {
                setId("");
                setData(null);
                setModal(false);
                setResponseLink(null);
              }}
            >
              <i className="fa-solid fa-xmark"></i>


            </button>
          )}
          <button
            className="btn btn-search-id"
            style={{
              borderRadius: "10px",
              fontSize: "14px",
              border: "1px solid #11111199",
              height: "100%",
              backgroundColor: "white",
              borderLeft: "none",
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              paddingRight: "15px",
            }}
            onClick={handleSearch}
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
        {data === 0 && (
          <p className="text-center" style={{ fontSize: "14px" }}>
            No se encontraron datos
          </p>
        )}
      </div>

      {data ? (
        <div
          className="w-100 mt-4 d-flex flex-row justify-content-center align-items-stretch"
          style={{
            borderRadius: "10px",
            gap: "10px",
            padding: "30px",
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            className="w-100 d-flex flex-column justify-content-start align-items-start flex-grow-1"
            style={{
              backgroundColor: "#FAFAFA",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 21V19C19 17.9391 18.5786 16.9217 17.8284 16.1716C17.0783 15.4214 16.0609 15 15 15H9C7.93913 15 6.92172 15.4214 6.17157 16.1716C5.42143 16.9217 5 17.9391 5 19V21"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <h6 className="mb-0" style={{ fontWeight: "700" }}>
                Datos del cliente
              </h6>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <div>
                <p
                  className="mb-0"
                  style={{ color: "#11111199", fontSize: "14px" }}
                >
                  Nombre
                </p>
                <p className="mb-0" style={{ fontWeight: "500" }}>
                  {data.NOMBRE}
                </p>
              </div>

              <div>
                <p
                  className="mb-0"
                  style={{ color: "#11111199", fontSize: "14px" }}
                >
                  Registrado
                </p>
                <p className="mb-0" style={{ fontWeight: "500" }}>
                  {data.REGISTRADO}
                </p>
              </div>

              <div>
                <p
                  className="mb-0"
                  style={{ color: "#11111199", fontSize: "14px" }}
                >
                  Teléfono
                </p>
                <p className="mb-0" style={{ fontWeight: "500" }}>
                  {data.TELEFONO.padStart(2, "0")}
                </p>
              </div>
            </div>
          </div>

          <div
            className="w-100 d-flex flex-column justify-content-start align-items-start flex-grow-1"
            style={{
              gap: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                backgroundColor: "#F1F1F1",
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "100%",
                borderRadius: "10px",
              }}
            >
              <h6 style={{ fontWeight: "700" }}>Mensaje </h6>

              <div
                style={{
                  borderRadius: "10px",
                  padding: "0px",
                  width: "100%",
                }}
              >
                <p className="mb-0 p-0">
                  {msj ? msj : "No hay mensaje asociado a este teléfono"}{" "}
                </p>
                <a
                  href={links}
                  style={{
                    color: loadingLink ? "#111111" : "#0066ff",
                    fontSize: "14px",
                    fontWeight: "500",
                    cursor: loadingLink ? "not-allowed" : "pointer",
                    pointerEvents: loadingLink ? "none" : "auto",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                >
                  {links}
                </a>
              </div>
            </div>

            <div
              style={{
                borderRadius: "10px",
                backgroundColor: "#F1F1F1",
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingLeft: "20px",
                paddingRight: "20px",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#111111"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 16V12"
                  stroke="#111111"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 8H12.01"
                  stroke="#111111"
                  strokeOpacity="0.6"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p className="mb-0">
                Ingresa al link para validar el número telefónico.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-100 mt-4 d-flex flex-column justify-content-center align-items-center">
          {data === 0 ? null : (
            <div
              className="d-flex flex-column justify-content-center align-items-center mt-5"
              style={{ gap: "10px" }}
            >
              <svg
                width="111"
                height="123"
                viewBox="0 0 111 123"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M89.3333 31.6667C96.6971 31.6667 102.667 25.6971 102.667 18.3333C102.667 10.9695 96.6971 5 89.3333 5C81.9695 5 76 10.9695 76 18.3333C76 25.6971 81.9695 31.6667 89.3333 31.6667Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M106 35.0002L98.833 27.8335"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M66.667 64.6665H75.0003"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M66.667 81.3335H75.0003"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M25.708 85.4999C26.5669 83.0582 28.1626 80.9435 30.2748 79.4474C32.3869 77.9514 34.9114 77.1479 37.4997 77.1479C40.088 77.1479 42.6124 77.9514 44.7246 79.4474C46.8368 80.9435 48.4324 83.0582 49.2913 85.4999"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M37.5003 77.1667C42.1027 77.1667 45.8337 73.4357 45.8337 68.8333C45.8337 64.231 42.1027 60.5 37.5003 60.5C32.898 60.5 29.167 64.231 29.167 68.8333C29.167 73.4357 32.898 77.1667 37.5003 77.1667Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M83.333 43.8335H16.6663C12.064 43.8335 8.33301 47.5645 8.33301 52.1668V93.8335C8.33301 98.4359 12.064 102.167 16.6663 102.167H83.333C87.9354 102.167 91.6664 98.4359 91.6664 93.8335V52.1668C91.6664 47.5645 87.9354 43.8335 83.333 43.8335Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p className="mb-0" style={{ color: "#11111199" }}>
                Nos has buscado todavía ninguna cédula
              </p>
            </div>
          )}
        </div>
      )}

      <Modal1
        open={modal}
        modalType={3}
        close={() => Open()}
        icon={
          responseLink && responseLink.status === 200
            ? "fa-solid fa-circle-check fa-2x modal-icon-success"
            : "fa-solid fa-circle-xmark fa-2x modal-icon-error"
        }
        modalText={
          responseLink && responseLink.status === 200
            ? "Validación de número telefónico exitosa"
            : "Validación de número telefónico fallida"
        }
      />
    </div>
  );
};

export default ActivarTlf;
