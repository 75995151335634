import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/Login/Login";
import Main from "../pages/Main/Main";
import Error404 from "../pages/Error404/Error404";
import RecoverPwd from "../pages/RecoverPwd/RecoverPwd";
import Reasignacion from "../pages/Reasignacion/Reasignacion";
import Aliados from "../pages/Aliados/Aliados";
import CambiarPwd from "../pages/CambiarPwd/CambiarPwd";
import Usuarios from "../pages/Usuarios/Usuarios";
import ClientesAsig from "../pages/reports/ClientesAsignados/ClientesAsignados";
import Instalaciones from "../pages/reports/Instalaciones/Instalaciones";
import CambioDePlan from "../pages/reports/CambioDePlan/CambioDePlan";
import Tracking from "../pages/Tracking/Tracking";
import OrdersxTime from "../pages/Settings/OrdersxTime";
import ActivarTlf from "../pages/ActivarTlf/ActivarTlf";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/dashboard" element={<Main />} />
          <Route path="/reasignar" element={<Reasignacion />} />
          <Route path="/recovery" element={<RecoverPwd />} />
          <Route path="/aliados" element={<Aliados />} />
          <Route path="/cambiar-pwd" element={<CambiarPwd />} />
          <Route path="/usuarios" element={<Usuarios />} />
          <Route path="/clientes-asignados" element={<ClientesAsig />} />
          <Route path="/instalaciones" element={<Instalaciones />} />
          <Route path="/cambio-de-plan" element={<CambioDePlan />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/ordenes-tiempos" element={<OrdersxTime />} />
          <Route path="/activar" element={<ActivarTlf />} />
          <Route path="*" element={<Error404 />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
