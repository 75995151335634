import React, { useEffect, useState, useMemo } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Pagination from "react-js-pagination";
import arrow_down from "../../assets/svg/arrow-down.svg";
import ExportExcel from "../../utils/export/ExportExcel/ExportExcel";
import ExportPDF from "../../utils/export/ExportPDF/ExportPDF";
import DateSelector from "../../components/DateSelector/DateSelector";
import Modal1 from "../../components/Modal/Modal";
import Search from "../../components/shared/SearchBar/SearchBar";
import Select1 from "../../components/shared/Select/Select";
import TableHeader from "../../components/shared/TableHeader/TableHeader";
import axios from "axios";
import Cookies from "universal-cookie";
import Loader from "../../components/Loaders/Loader";
import ModalOrden from "../../components/Modal/ModalOrden/ModalOrden";
import emptyData from "../../assets/img/empty.png";

const ClientesAsig = () => {
  const cookies = new Cookies();
  const [comments, setComments] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalType, setmodalType] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [tkn] = useState(cookies.get("tkn"));
  const [error, setError] = useState(false);

  const [multipleSearch, setMultipleSearch] = useState({
    CLIENTE_ASIG_AC: "",
    MDT: "",
    PLAN_SOLICITADO: "",
    NAP: "",
  });

  const [modalInfo, setInfo] = useState({
    plan: "",
    NAP: "",
  });
  const [infoupd, setinfoupd] = useState(null);

  const [dateFilter, setdateFilter] = useState("");
  const [downloadExcel, setExport] = useState("");
  const ITEMS_PER_PAGE = 6;
  const now = new Date();
  const yesterdayBegin = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1
  );

  const [modal2, setModal2] = useState(false);

  const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const [value, onChange] = useState([yesterdayBegin, todayEnd]);
  const [qtyNN, setQtyNN] = useState(0);

  const headers = [
    { name: "Registrado", field: "REGISTRADO", sortable: true },
    { name: "C.I", field: "CI_RIF", sortable: false },
    { name: "Nombre", field: "NOMBRE", sortable: true },
    { name: "Teléfono", field: "TELEFONO", sortable: false },
    { name: "Correo Electrónico", field: "CORREO", sortable: false },
    { name: "MDT", field: "MDT", sortable: false },
    { name: "Aliado", field: "CLIENTE_ASIG_AC", sortable: false },
    {
      name: "Fecha de Asignación",
      field: "FCH_ASIG_CLIENTE_AC",
      sortable: true,
    },
    { name: "Acciones", field: "", sortable: false },
  ];

  // modal
  const Open = () => {
    setModal(!modal);
  };

  const Open2 = () => {
    setModal2(!modal2);
  };

  const SetModalInfo = (plan, nap) => {
    var info_ad = {
      plan: plan,
      NAP: nap,
    };

    info_ad = Object.fromEntries(
      Object.entries(info_ad).filter(([_, v]) => v !== "")
    );

    setInfo(info_ad);
    setmodalType(2);
    Open();
  };

  useEffect(() => {
    const getData = () => {
      axios
        .get(
          `${process.env.REACT_APP_API}${process.env.REACT_APP_CLIENTES_ASIGNADOS}`,
          {
            headers: {
              Authorization: `Bearer ${tkn}`,
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          setComments(res.data.records);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
          setError(true);
        });
    };

    getData();
  }, [tkn]);

  // mdt
  const mdt = useMemo(
    () =>
      [...new Set(comments.map((item) => item.MDT))].map((type) => {
        return { label: type, value: type };
      }),
    [comments]
  );

  // aliados
  const aliados = useMemo(
    () =>
      [...new Set(comments.map((item) => item.CLIENTE_ASIG_AC))].map((type) => {
        return { label: type, value: type };
      }),
    [comments]
  );

  // plan
  const plan = useMemo(
    () =>
      [...new Set(comments.map((item) => item.PLAN_SOLICITADO))].map((type) => {
        return { label: type, value: type };
      }),
    [comments]
  );

  // nap
  const nap_d = useMemo(
    () =>
      [...new Set(comments.map((item) => item.NAP))].map((type) => {
        return { label: type, value: type };
      }),
    [comments]
  );

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // Custom filter

    if (dateFilter === "Personalizado") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ FCH_ASIG_CLIENTE_AC }) =>
            new Date(FCH_ASIG_CLIENTE_AC) > value[0] &&
            new Date(FCH_ASIG_CLIENTE_AC) < value[1]
        );
      computedComments = filterByDate(computedComments);
    }

    // Filter by year

    if (dateFilter === "Este año") {
      var p = new Date().getFullYear();
      const filterByDate = (arr, yearFilter) =>
        arr.filter(
          ({ FCH_ASIG_CLIENTE_AC }) =>
            new Date(FCH_ASIG_CLIENTE_AC).getFullYear() === yearFilter
        );
      computedComments = filterByDate(computedComments, p);
    }

    // Filter by Month

    if (dateFilter === "Este mes") {
      var filter_year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;

      const filterByDate = (arr, yearFilter, monthF) =>
        arr.filter(
          ({ FCH_ASIG_CLIENTE_AC }) =>
            new Date(FCH_ASIG_CLIENTE_AC).getMonth() + 1 === yearFilter &&
            new Date(FCH_ASIG_CLIENTE_AC).getFullYear() === monthF
        );

      computedComments = filterByDate(computedComments, month, filter_year);
    }

    // Filter by Week
    if (dateFilter === "Esta semana") {
      const getStartOfWeek = (date) => {
        date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - date.getDay());
        return date;
      };

      const getEndOfWeek = (date) => {
        date = getStartOfWeek(date);
        date.setDate(date.getDate() + 6);
        return date;
      };

      var inicio = getStartOfWeek();
      var final = getEndOfWeek();

      const filterByDate = (arr) =>
        arr.filter(
          ({ FCH_ASIG_CLIENTE_AC }) =>
            new Date(FCH_ASIG_CLIENTE_AC) > inicio &&
            new Date(FCH_ASIG_CLIENTE_AC) < final
        );
      computedComments = filterByDate(computedComments);
    }

    // Filter (Today)
    if (dateFilter === "Hoy") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ FCH_ASIG_CLIENTE_AC }) =>
            new Date(FCH_ASIG_CLIENTE_AC).setHours(0, 0, 0, 0) ===
            new Date().setHours(0, 0, 0, 0)
        );

      computedComments = filterByDate(computedComments);
    }

    // Filter (Yesterday)
    if (dateFilter === "Ayer") {
      const filterByDate = (arr) =>
        arr.filter(
          ({ FCH_ASIG_CLIENTE_AC }) =>
            new Date(FCH_ASIG_CLIENTE_AC).setHours(0, 0, 0, 0) ===
            new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate() - 1
            ).setHours(0, 0, 0, 0)
        );

      computedComments = filterByDate(computedComments);
    }

    // check how many elements aren't empty inside multipleSearch
    var qty = 0;
    for (var key in multipleSearch) {
      if (multipleSearch[key] !== "") {
        qty = qty + 1;
      }
      setQtyNN(qty);
    }

    // Global search
    if (search !== "" && search !== null) {
      computedComments = computedComments.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    // search based on multiple categories

    if (
      qtyNN >= 1 &&
      Object.values(multipleSearch).every((x) => x === null || x === "") ===
        false
    ) {
      let check = {
        MDT: `${multipleSearch.MDT}`,
        CLIENTE_ASIG_AC: `${multipleSearch.CLIENTE_ASIG_AC}`,
        PLAN_SOLICITADO: `${multipleSearch.PLAN_SOLICITADO}`,
        NAP: `${multipleSearch.NAP}`,
      };

      // remove empty elements
      check = Object.fromEntries(
        Object.entries(check).filter(([_, v]) => v !== "")
      );

      // check inside records
      computedComments = computedComments.filter((result) =>
        Object.entries(check).every(([key, val]) =>
          val !== "" ? result[key] === val : true
        )
      );
    }

    setExport(computedComments);
    setTotalItems(computedComments.length);

    //Sorting rows
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Slice current Page
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    qtyNN,
    multipleSearch,
    dateFilter,
    value,
  ]);

  const TableInformation = ({ info }) => {
    return (
      <>
        {info.map((comment, index) => (
          <tr key={index}>
            <td>{comment.REGISTRADO.replace("T", " ")}</td>
            <td>{comment.CI_RIF}</td>
            <td>{comment.NOMBRE}</td>
            <td>{comment.TELEFONO}</td>
            <td>{comment.CORREO}</td>
            <td>{comment.MDT}</td>
            <td>{comment.CLIENTE_ASIG_AC}</td>
            <td>{comment.FCH_ASIG_CLIENTE_AC.replace("T", " ")}</td>

            <td
              style={{
                display: "flex",
                gap: "6px",
                justifyContent: "center",
              }}
            >
              <button
                className="btn btn-secondary btn-vmas border-0"
                onClick={() => {
                  SetModalInfo(comment.PLAN_SOLICITADO, comment.NAP);
                }}
              >
                <i className="fa-solid fa-eye" style={{ fontSize: "14px" }}></i>
              </button>

              <button
                className="btn btn-primary btn-vmas border-0"
                onClick={() => {
                  setinfoupd({
                    uuid: comment.UUID,
                    fch_convenio_inst: comment.FCH_CONVENIO_INST,
                  });

                  Open2();
                }}
              >
                <i
                  className="fa-solid fa-pen-to-square"
                  style={{ fontSize: "14px" }}
                ></i>
              </button>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="ps-4 pe-4 pt-4">
      <div className="col mb-3 col-12 text-center">
        <div className="row">
          <p className="asignacion-title">Historial de Asignaciones</p>

          <div>
            {isLoading === true ? (
              <div className="w-100 h-100 d-flex pt-5">
                <div className="mx-auto h-100">
                  <Loader />
                </div>
              </div>
            ) : (
              <>
                {!error ? (
                  <div>
                    <div className="row">
                      <div className="col-md-12 mt-1 mb-4 d-flex flex-row justify-content-between align-items-center  pe-4">
                        <div className="d-flex w-100 justify-content-between filters-col">
                          <div
                            className="d-flex me-2 search-bar-tableb"
                            style={{ width: "286px" }}
                          >
                            <div className="dropdown me-1 ">
                              <button
                                className="btn btn-primary dropdown-toggle download-btn ps-1 pe-1 pt-1 pb-1"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={arrow_down} alt="ícono flecha" />
                              </button>
                              <ul
                                className="dropdown-menu drpdwn-export"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="pdf-list">
                                  <ExportExcel data={downloadExcel} rep_t={4} />
                                </li>

                                <li className="pdf-list">
                                  <ExportPDF data={downloadExcel} rep_t={4} />
                                </li>
                              </ul>
                            </div>

                            <Search
                              onSearch={(value) => {
                                setSearch(value);
                                setCurrentPage(1);
                              }}
                              placeholder={"Buscar Por Nombre del Aliado"}
                            />
                          </div>

                          <div className="d-flex drpdwn-filters">
                            <div className="d-flex align-items-center me-3 drpd select-f">
                              <div className="btn-group">
                                <button
                                  className="btn btn-light dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuClickableInside"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="outside"
                                  aria-expanded="false"
                                >
                                  Seleccionar Fecha
                                  <svg
                                    height="20"
                                    width="20"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                    focusable="false"
                                    className="css-tj5bde-Svg ms-2"
                                  >
                                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                  </svg>
                                </button>
                                <ul
                                  className="dropdown-menu pe-2 ps-2"
                                  aria-labelledby="dropdownMenuClickableInside"
                                >
                                  <DateSelector
                                    onSearch={(value) => {
                                      setdateFilter(value);
                                      setCurrentPage(1);
                                    }}
                                    op={"2"}
                                  />

                                  {dateFilter === "Personalizado" && (
                                    <DateRangePicker
                                      calendarAriaLabel="Toggle calendar"
                                      clearAriaLabel="Clear value"
                                      dayAriaLabel="Day"
                                      monthAriaLabel="Month"
                                      nativeInputAriaLabel="Date"
                                      clearIcon={null}
                                      yearAriaLabel="Year"
                                      format={"MM-dd-y"}
                                      onChange={onChange}
                                      value={value}
                                      rangeDivider={"al"}
                                    />
                                  )}
                                </ul>
                              </div>
                            </div>

                            <div className="filters2 filtros-mdt d-flex ">
                              <div className="d-flex flex-column select-f">
                                <Select1
                                  onSearch={(value) => {
                                    setMultipleSearch({
                                      ...multipleSearch,
                                      CLIENTE_ASIG_AC: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                  data={aliados}
                                  qty={qtyNN}
                                  placeholder={"Aliado"}
                                />
                              </div>

                              <div className="d-flex select-f">{/* aa */}</div>
                              <div className="d-flex flex-column select-f">
                                <Select1
                                  onSearch={(value) => {
                                    setMultipleSearch({
                                      ...multipleSearch,
                                      MDT: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                  data={mdt}
                                  placeholder={"MDT"}
                                />
                              </div>

                              <div className="d-flex flex-column select-f">
                                <Select1
                                  onSearch={(value) => {
                                    setMultipleSearch({
                                      ...multipleSearch,
                                      PLAN_SOLICITADO: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                  data={plan}
                                  placeholder={"Plan"}
                                />
                              </div>

                              <div className="d-flex flex-column select-f">
                                <Select1
                                  onSearch={(value) => {
                                    setMultipleSearch({
                                      ...multipleSearch,
                                      NAP: value,
                                    });
                                    setCurrentPage(1);
                                  }}
                                  data={nap_d}
                                  placeholder={"NAP"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-b table-mdt table-bordered">
                        <TableHeader
                          headers={headers}
                          onSorting={(field, order) =>
                            setSorting({ field, order })
                          }
                          id={"selectAll"}
                        />

                        <tbody>
                          <TableInformation info={commentsData} />
                        </tbody>
                      </table>
                    </div>

                    <div className="row">
                      <p className="mb-3 total-reg total-reg-ac">
                        Total Registros: <span> {downloadExcel.length}</span>
                      </p>
                    </div>

                    <div className="d-flex w-100">
                      <div className="ms-auto">
                        <Pagination
                          activePage={currentPage}
                          itemsCountPerPage={ITEMS_PER_PAGE}
                          totalItemsCount={totalItems}
                          disabledClass={"disabled-page"}
                          pageRangeDisplayed={5}
                          onChange={(page) => setCurrentPage(page)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="w-100 d-flex h-100">
                      <div className="mx-auto my-auto mt-5 text-center">
                        <img
                          src={emptyData}
                          alt="Data no disponible"
                          className="m-auto"
                        />

                        <p
                          className="text-center"
                          style={{
                            color: "#AEB8C2",
                            fontWeight: "bold",
                          }}
                        >
                          No se han encontrado registros.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal1
        open={modal}
        modalType={modalType}
        close={() => Open()}
        uInfo={modalInfo}
      />

      <ModalOrden date={infoupd} open={modal2} close={() => Open2()} />
    </div>
  );
};

export default ClientesAsig;
