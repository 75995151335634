import React, { useState, useEffect } from "react";
import Button from "../../shared/Button/Button";
import Cookies from "universal-cookie";
import "../Modal.css";
import ReactModal from "react-modal";

const ModalOrden = ({ date, open, close }) => {
  const [openM, setOpen] = useState();
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tkn] = useState(cookies.get("tkn"));

  var ajsmj;
  if (date) {
    ajsmj = new Date(date.fch_convenio_inst);
  }
  const [formInfo, setformInfo] = useState({
    fecha: "",
    comentario: "",
  });
  const [response, setresponse] = useState(null);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const onSubmit = () => {
    setIsLoading(true);

    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");

    formInfo["modifiedby"] = base64ToStringNew;
    formInfo["id"] = date.uuid;

    fetch(`${process.env.REACT_APP_API}${process.env.REACT_APP_FECHA_EXP}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tkn}`,
      },
      body: JSON.stringify(formInfo),
    })
      .then((response) => response.json())
      .then((json) => {
        setIsLoading(false);
        var resp = JSON.parse(json);
        setresponse(resp);
      })
      .catch((error) => {
        setIsLoading(false);
        setresponse(null);
        console.error("Error:", error);
      });
  };

  return (
    <ReactModal
      onRequestClose={close}
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered"
      tabIndex="-1"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .4)",
        },
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content modal1 p-2">
          <div className="modal-header mhdr">
            <button
              type="button"
              className="btn-close close-modal"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                if (response) {
                  close();
                  setTimeout(() => {
                    window.location.reload();
                  }, 600);
                } else {
                  close();
                }
              }}
            ></button>
          </div>

          <div className="modal-body mbdy">
            <>
              {response ? (
                <div className="text-center">
                  {response && !response.status ? (
                    <i className="fa-solid fa-circle-xmark mb-2 fa-2xl modal-icon-error"></i>
                  ) : (
                    <i className="fa-solid fa-circle-check modal-icon-success mb-2 fa-2xl"></i>
                  )}

                  {response && response.message && (
                    <p className="mt-3 modal-error-txt">{response.message}</p>
                  )}
                </div>
              ) : (
                <div className="d-flex">
                  <div className="d-flex flex-column m-auto w-100 align-items-center">
                    <div className="w-100 text-center pb-3">
                      <label
                        className="fw-bold"
                        style={{
                          color: "#0066ff",
                        }}
                      >
                        Modificar Fecha de Expiración
                      </label>
                    </div>

                    <div className="d-flex w-100 justify-content-center">
                      <label>Fecha Actual: </label>

                      {ajsmj && (
                        <p className="ms-1 fw-bold">
                          {new Date(ajsmj).toLocaleString("es-VE")}
                        </p>
                      )}
                    </div>

                    <div className="d-flex flex-column justify-content-center w-100 gap-3">
                      <div className="d-flex flex-column">
                        <label>Nueva Fecha</label>

                        <input
                          type="datetime-local"
                          className={`form-control-ac  border-end rounded-pill ${
                            error ? "border-red" : "border-secondary"
                          }`}
                          onChange={(e) => {
                            var valueDate = e.target.value;

                            if (valueDate) {
                              if (new Date(valueDate) > new Date(ajsmj)) {
                                setError(false);
                                setformInfo((prevState) => ({
                                  ...prevState,
                                  fecha: valueDate,
                                }));
                              } else {
                                setError(true);
                              }
                            }
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column">
                        <label>Comentario</label>
                        <textarea
                          className="form-control-ac border-secondary border-end"
                          style={{
                            resize: "none",
                          }}
                          onChange={(e) => {
                            var valueDate = e.target.value.trim();

                            setformInfo((prevState) => ({
                              ...prevState,
                              comentario: valueDate,
                            }));
                          }}
                        />
                      </div>

                      <div
                        style={{
                          pointerEvents: !Object.values(formInfo).every(
                            (field) => !!field
                          )
                            ? "none"
                            : "auto",
                          opacity: !Object.values(formInfo).every(
                            (field) => !!field
                          )
                            ? "0.2"
                            : "1",
                        }}
                      >
                        <Button
                          text={"Guardar"}
                          loading={isLoading}
                          myClass={"btn btn-save pe-4 ps-4 w-100"}
                          funct={onSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default ModalOrden;
