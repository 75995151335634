import React from "react";
import Timeline from "../../HorizontalTimeline/HorizontalTimeline";

const ModalTracking = ({ data }) => {
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="circle-order rounded-circle"></div>
        <p className="mb-0 ms-2 tracking-id">#{data ? data.id : 0}</p>
      </div>

      <div className="mt-2">
        <p className="mb-0 fw-bold info-tracking-txt">Información</p>

        <div className="tracking-info-wrapper mt-2">
          {data.direccion !== "" && (
            <div>
              <div className="w-100">
                <label className="info-tracking-txt">Dirección</label>
                <p className="mb-2 fw-bold info-tracking-txt2">
                  {data.direccion}
                </p>
              </div>
            </div>
          )}

          <div className="d-flex w-100">
            {data.comentario_anulacion !== "" && (
              <div className="w-100">
                <label className="info-tracking-txt">
                  Comentario Anulación
                </label>
                <p className="mb-2 fw-bold info-tracking-txt2">
                  {data.comentario_anulacion}
                </p>
              </div>
            )}

            {data.comentario_enespera !== "" && (
              <div className="w-100">
                <label className="info-tracking-txt">
                  Comentario en Espera
                </label>
                <p className="mb-2 fw-bold info-tracking-txt2">
                  {data.comentario_enespera}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-3 mb-3 d-flex align-items-center w-100 justify-content-between">
        <div>
          <h4 className="info-tracking-txt fw-bold mb-0">
            Seguimiento de la orden
          </h4>
          <p className="info-tracking-txt2">Tracking</p>
        </div>
        <div>
          <p
            className="info-tracking-txt2 mb-0"
            style={{
              fontWeight: 600,
            }}
          >
            {data.tracking.length} estatus
          </p>
        </div>
      </div>

      <Timeline data={data} />
    </>
  );
};

export default ModalTracking;
