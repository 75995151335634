import React, { useState } from "react";

import Error403 from "../Error403/Error403";
import Navbar from "../../components/shared/Navbar/Navbar";
import Sidebar from "../../components/shared/Sidebar/Sidebar";
import TrackingList from "../../views/Tracking/Tracking";

const ClientesAsignados = () => {
  const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("isLogedIn")));

  return (
    <>
      {isLoggedIn === true ? (
        <div className="wrapper w-100 d-flex align-items-stretch">
          <Sidebar />
          <div id="content" className="main-cnt">
            <div className="container-fluid main-ct w-100 ">
              <Navbar />
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <TrackingList />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Error403 />
      )}
    </>
  );
};

export default ClientesAsignados;
