import React, { useState, useEffect } from "react";
import "./Modal.css";
import ReactModal from "react-modal";
import AgregarAliado from "../../pages/Aliados/AgregarAliado";
import ModificarAliado from "../../pages/Aliados/ModificarAliado";
import ModalTracking from "./ModalTracking/ModalTracking";

const Modal1 = ({
  open,
  close,
  uInfo,
  modalType,
  modalTitle,
  modalText,
  icon,
  title_class,
  cod,
}) => {
  const [openM, setOpen] = useState();

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <ReactModal
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered w-100"
      tabIndex="-1"
      shouldCloseOnOverlayClick={true}
      onRequestClose={close}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content modal1">
          <div className="modal-header mhdr">
            <button
              type="button"
              className="btn-close close-modal mt-1 me-1"
              onClick={close}
            ></button>
          </div>

          <div className="modal-body mbdy">
            {(() => {
              switch (modalType) {
                case 2:
                  return (
                    <div className="text-center">
                      <div className="d-flex align-items-center flex-wrap">
                        {Object.entries(uInfo).map(([key, val], i) => (
                          <React.Fragment key={i}>
                            {val && val.trim() !== "" && (
                              <div className="d-flex flex-column me-5 text-start">
                                {key === "nodo" ? (
                                  <label className="text-start text-label">
                                    OLT
                                  </label>
                                ) : (
                                  <label className="text-start text-label">
                                    {(
                                      key.charAt(0).toUpperCase() + key.slice(1)
                                    ).replace(/_/g, " ")}
                                  </label>
                                )}
                                <p className="modal-text-1">{val}</p>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  );
                case 3:
                  return (
                    <div className="text-center">
                      <i className={icon}></i>
                      <h4 className={title_class}>{modalTitle}</h4>
                      <p className="modal-error-txt">{modalText}</p>
                    </div>
                  );
                case 4:
                  return <AgregarAliado />;
                case 5:
                  return <ModificarAliado cod={cod} />;
                case 6:
                  return <ModalTracking data={cod} info={uInfo}/>;

                default:
                  return (
                    <div className="text-center">
                      <i className="fa-solid fa-circle-xmark fa-lg red-icon"></i>
                      <h4 className="modal-error">¡Error Conexión Fallida!</h4>
                      <p className="modal-error-txt">
                        Por favor verifique su conexión a Internet
                      </p>
                    </div>
                  );
              }
            })()}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal1;
