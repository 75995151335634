import creadaicon from "../../assets/img/creada.png";
import asignadaicon from "../../assets/img/asignada.png";
import planificadaicon from "../../assets/img/planificada.png";
import enesperaicon from "../../assets/img/enespera.png";
import enejecucionicon from "../../assets/img/enejecucion.png";
import instaladoicon from "../../assets/img/instalado.png";
import canceladoicon from "../../assets/img/cancelada.png";

export const trackingColors = {
  "solicitud creada": {
    solid: "#0066ff",
    light: "#0066FF29",
    icon: creadaicon,
  },
  "asignada a tecnico": {
    solid: "#0066ff",
    light: "#0066FF29",
    icon: asignadaicon,
  },
  "asignada a aliado comercial": {
    solid: "#0066ff",
    light: "#0066FF29",
    icon: asignadaicon,
  },
  planificada: {
    solid: "#0066ff",
    light: "#0066FF29",
    icon: planificadaicon,
  },
  "en espera": {
    solid: "#FF7C33",
    light: "#FF7C3329",
    icon: enesperaicon,
  },
  "en ejecucion": {
    solid: "#0066ff",
    light: "#0066FF29",
    icon: enejecucionicon,
  },
  instalado: {
    solid: "#229E00",
    light: "#229E0029",
    icon: instaladoicon,
  },
  cancelado: {
    solid: "#F55B5D",
    light: "#F55B5D29",
    icon: canceladoicon,
  },
};
